<template>
    <div class='recharge'>
        <div class="recharge_input">
            <p>选择或输入充值金额(元)</p>
            <div class="recharge_input_box">
                <label>￥</label><input type="number" ref="inputRef" @input="inputEvent" v-model="inputValue" placeholder="请输入您的充值金额"/>
            </div>
        </div>
        <div class="recharge_choose">
                <div 
                    class="recharge_choose_item" 
                    :class="{'recharge_choose_active': index == currentMoneyItemsIndex}"
                    v-for="(item, index) in moneyItems" 
                    @click="changeMoney(index, item.value)"
                    :key="index">
                        <button v-if="item.type == 'button'">
                            <span>{{item.value}}元</span>
                            <span v-show="false"  :class="{'red': item.discounts && index !== currentMoneyItemsIndex}">{{item.discounts ? item.info : '暂无优惠'}}</span>
                        </button>
                </div>
            </div>
        <div class="recharge_type">
            <p>支付方式</p>
            <ul class="recharge_type_items">
                <li 
                class="recharge_type_items_item" 
                v-for="(item, index) in payTypes" 
                @click="currentTypesIndex = index"
                :key="index">
                    <div class="recharge_type_items_item_left">
                        <img :src="item.img"/>
                        <span>{{item.label}}</span>
                    </div>
                    <div class="recharge_type_items_item_right" :class="{'recharge_type_items_item_active': index == currentTypesIndex}">
                    </div>
                </li>
            </ul>
        </div>
        <div class="recharge_tips">
            <h3>温馨提示:</h3>
            <ul>
                <li v-for="(item, index) in tips" :key="index">{{index+1}}. {{item}}</li>
            </ul>
        </div>
        <div class="recharge_submit">
            <button @click="submit">立即充值</button>
        </div>
    </div>
</template>
<script>
import { reactive, toRefs, ref, onMounted } from 'vue'
import { recharge } from '../utils/api'
import { getUserInfo, jumpTo, toast, userOperationEvent, sleep } from '../utils/util'
export default {
    name: 'recharge',
    setup() {
         const state= reactive({
             moneyItems: [
                 {type: 'button', value: 10, discounts: false},
                 {type: 'button', value: 30, discounts: false},
                 {type: 'button', value: 50, discounts: true, info: '送2注共4元'},
                 {type: 'button', value: 100, discounts: true, info: '送6注共12元'},
                 {type: 'button', value: 200, discounts: true, info: '送15注共30元'},
                 {type: 'button', value: 500, discounts: true, info: '送15注共30元'},
                 
             ],
             currentMoneyItemsIndex: 3,
             payTypes: [
                 {label: '支付宝', typeValue: 'h5AliPay', img: require('../assets/imgs/account-epay.png')},
                //  {label: '云闪付', typeValue: 'h5YsfPay', img: require('../assets/imgs/account-yl.png')},
                //  {label: '银联', typeValue: 'h5YlPay', img: require('../assets/imgs/ys_pay.png')}
             ],
             inputValue: '100',
             currentTypesIndex: 0,
             tips: [
                 '充值金额不能小于10元；',
                 '账户充值均进入福彩账户，保障资金安全；',
                 '充值金额仅限短信及电话投注，不可提现。',
                //  '用户一次性充值：满50元送2注共4元，充值满100元送6注共12元、充值满200元送15注共30元,先到先得，送完为止（获赠的机选单式“双色球”彩票于次日一次性发送至该用户的电话投注账户，每个账户每月仅限参与一次充值活动）'
             ]
        })
        // banlance()
        const inputRef = ref()
        async function submit() {
            try {
                const value = Number.parseFloat(state.inputValue)
                if(!value || isNaN(value) || value < 10) {
                    toast('最小充值金额为10元')
                    return
                }
                const params = {
                    money: state.inputValue,
                    providerMid: state.payTypes[state.currentTypesIndex].typeValue
                }
                const userInfo = await getUserInfo()
                userOperationEvent(4, userInfo.mobile, {amount: state.inputValue} )
                const result = await recharge(params)
                if (result.code == 0) {
                    // window.open(result.data)
                    window.location.href = result.data
                    jumpTo('/mine')
                }
            } catch(e) {
                console.error(e)
            }
        }
        function changeMoney(index, value) {
            state.currentMoneyItemsIndex = index
            state.inputValue = value
            inputRef.value.focus()
        }
        onMounted(() => {
            inputRef.value.focus()
        })
        async function inputEvent(e) {
            state.currentMoneyItemsIndex = 9
            e.target.value = e.target.value.replace(/[^\d]/g,'')
            const inputValue = e.data
            const data = e.target.value
            if (inputValue == '.') {
                state.inputValue = parseInt(data)
                state.inputValue = state.inputValue + 1
                await sleep(2)
                state.inputValue = state.inputValue - 1
            }
        }
        return {
            ...toRefs(state),
            submit,
            changeMoney,
            inputRef,
            inputEvent
        }
    },
}
</script>
<style lang='scss' scoped>
.recharge {
   position: absolute;
   top: 0;
   left: 0;
   right: 0;
   bottom: 0;
   overflow: auto;
   background: $bg-gray;
   &_input {
       padding: 20px 10px 0 10px;
    //    background: #fff;
    //    margin-top: 15px;
       &>p {
           font-size: 17px;
           small {
               color: $gray-middle;
               padding-left: 5px;
               font-size: $font-middle;
           }
       }
       &_box {
           display: flex;
           font-size: 42px;
           margin: 10px 0;
           border: solid $line-gray 1px;
           border-radius: $box-radius-small;
           background: #fff;
           input {
               font-size: 36px;
               font-weight: 600;
               max-width: 80%;
               &::placeholder{
                   font-size: $font-big;
                   font-weight: 400;
                   line-height: 36px;
               }
           }
       }
   }
   &_choose {
       padding: 0px 10px 20px 10px;
       display: flex;
       flex-wrap: wrap;
       justify-content: space-between;
       align-items: center;
       &_item {
            width: 27%;
            background: #fff;
            color: #000;
            flex-wrap: nowrap;
            border: solid $gray-light 1px;
            border-radius: $box-radius-small;
            overflow: hidden;
            margin: 20px 0 0;
            padding: 10px 0 10px 0;
            text-align: center;
            font-size: $font-max;
            span {
                display: block;
                &:last-child{
                    font-size: $font-small;
                }
            }
       }
       &_active {
            border: solid $theme-dark 1px;
            background: $theme-dark;
            color: #fff;
       }
   }
    &_type {
        p {
            padding: $margin-big*1.6 $margin-big $margin-big $margin-big;
            font-size: $font-big;
            color: $gray-middle;
            color: #000;
        }
        &_items {
            background: #fff;
            padding: 0 $margin-big;
            // color: $gray-middle;
            &_item {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 0;
                border-bottom: dashed $line-gray 1px;
                font-size: $font-big;
                &:last-child{
                    border-bottom: none;
                }
                &_left {
                    display: flex;
                    align-items: center;
                    img {
                        width: 17px;
                        height: 100%;
                        margin-right: 8px;
                    }
                }
                &_right {
                    width: 13px;
                    height: 13px;
                    border: solid $line-gray 1px;
                    border-radius: 50%;
                }
                &_active {
                    background: url('../assets/imgs/card_to_value_choosed.png') center center no-repeat;
                    background-size: contain;
                    border: none;
                }
            }
        }
    }
    &_tips{
        padding: $margin-big;
        h3 {
            font-weight: 400;
            color: $theme-dark;
            font-size: $font-small;
            padding: 15px 0 0px 0;
        }
        li {
            font-size: $font-small;
            color: $gray-middle;
            padding: 5px 0 0 0;
        }
    }
    &_submit {
        margin: $margin-big;
        button {
            color: #fff;
            width: 100%;
            text-align: center;
            background: $theme-dark;
            border-radius: $box-radius-small;
            padding: 12px 10px;
        }
    }
}
</style>